import { useCartsQuery } from '@holo/gql';
import { useCartId } from './useCartId';
import { useStoreId } from './useStoreId';

export const useCarts = () => {
  const cartId = useCartId();
  const storeId = useStoreId();

  const { data, refetch } = useCartsQuery({
    skip: Boolean(storeId),
    variables: {
      id: cartId,
    },
  });

  return {
    carts: data?.carts,
    refetch,
  };
};
