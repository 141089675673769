import type { ImageProps as BaseImageProps } from 'next/image';
import NextImage from 'next/image';
import type { ImageLoaderProps } from 'next/image';
import { YStack } from 'tamagui';
import type { YStackProps } from 'tamagui';
import { skeleton } from '../../skeleton';

type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

type CustomImageProps = {
  src?: BaseImageProps['src'] | null;
  loading?: boolean;
  borderRadius?: number;
  objectFit?: ObjectFit;
  wrapperProps?: YStackProps;
  blurDataURL?: string | undefined | null;
};

type CustomImageLoaderProps = {
  square?: boolean;
} & ImageLoaderProps;

type ImageProps = Omit<BaseImageProps, 'loading' | 'src' | 'blurDataURL'> & CustomImageProps;

const CLOUDINARY_ACCOUNT = process.env.NEXT_PUBLIC_CLOUDINARY_ACCOUNT;

const cloudinaryLoader = ({ src, width, square, quality = 70 }: CustomImageLoaderProps): string => {
  let params;

  if (square) {
    params = `w_${width},h_${width},c_fill`;
  }

  if (width && !square) {
    params = `w_${width}`;
  }

  // NOTE: f_auto does not work in iOS (avif), hence f_webp atm
  return `https://${CLOUDINARY_ACCOUNT}.mo.cloudinary.net/${src}?tx=${params},q_${quality},f_webp`;
};

const Image = ({
  src,
  loading,
  width,
  height,
  borderRadius,
  objectFit,
  style = {},
  wrapperProps,
  blurDataURL,
  ...restProps
}: ImageProps) => {
  // removing the "placeholder='blur'" property to improve performance on smaller images
  const disableBlur = width && +width > 40;

  // 1:1 ratio (enable crop)
  const square = width === height;

  const customLoader = (p: CustomImageLoaderProps) => cloudinaryLoader({ ...p, square });

  if (loading) {
    return (
      <YStack width={width} height={height} className={skeleton.className('box', true)} borderRadius={borderRadius} />
    );
  }

  // NOTE: This is a placeholder when src is empty
  if (!src) {
    return (
      <YStack {...wrapperProps}>
        <YStack
          // @ts-ignore
          style={style}
          width={width}
          height={height}
          borderRadius={borderRadius}
          backgroundColor="$border"
        />
      </YStack>
    );
  }

  return (
    <YStack {...wrapperProps}>
      <NextImage
        loader={customLoader}
        src={src}
        {...(blurDataURL && disableBlur && { blurDataURL, placeholder: 'blur' })}
        width={width}
        height={height}
        style={{
          ...style,
          ...{
            borderRadius,
            objectFit,
            overflow: borderRadius || objectFit === 'cover' ? 'hidden' : 'visible',
          },
        }}
        {...restProps}
      />
    </YStack>
  );
};

export default Image;
