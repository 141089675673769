import { getAccessToken, getUserFromToken } from '@holo/jwt';
import type { User } from '@holo/jwt';
import { useEffect, useState } from 'react';

export const useSession = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const token = getAccessToken();
  const result = getUserFromToken(token as string);

  useEffect(() => {
    setUser(result);
    setLoading(false);
  }, []);

  return {
    loading,
    user,
  };
};
