/*
useProductCategoriesSpy

This hook is used to keep track of what product category (product type and sub type) is "selected" by the scroll position.
It uses the context from ProductCategoriesProvider to get a list of all elements in a Map. That provider also exposes
some functions to register a section, detect closest section and the active id.

In this hook we compose a new registerOnPress function based on the one from the provider since this takes a scrollOffset
and an onBeforeScroll option. This is because it can be used in different places and therefore the scrollOffset and onBeforeScroll
callback might be different.

See ProductCategoriesProvider for more code on how this works.
*/

import { ProductCategoriesSpyContext } from '@/providers/ProductCategoriesProvider';
import { useCallback, useContext, useEffect } from 'react';

export type UseProductCategoriesSpyOptions = {
  scrollOffset: number;
  onBeforeScroll?: () => void;
};

const defaultOptions: UseProductCategoriesSpyOptions = {
  scrollOffset: 0,
};

export const useProductCategoriesSpy = (inputOptions: UseProductCategoriesSpyOptions = defaultOptions) => {
  const options = {
    ...defaultOptions,
    ...inputOptions,
  };

  const context = useContext(ProductCategoriesSpyContext);
  const detectClosest = useCallback(() => {
    context.detectClosest({ offset: options.scrollOffset });
  }, []);

  useEffect(() => {
    if (context.sections.size <= 0) {
      return undefined;
    }

    detectClosest();

    window.addEventListener('resize', detectClosest);
    window.addEventListener('scroll', detectClosest);

    return () => {
      window.removeEventListener('resize', detectClosest);
      window.removeEventListener('scroll', detectClosest);
    };
  }, []);

  const handleRegisterOnPress = (id: string) => {
    return context.registerOnPress(id, {
      offset: options.scrollOffset,
      onBeforeScroll: options.onBeforeScroll,
    });
  };

  return {
    ...context,
    registerOnPress: handleRegisterOnPress,
  };
};
