import { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import type { TamaguiElement } from 'tamagui';
import type { BaseInputProps } from './BaseInput';
import BaseInput from './BaseInput';

export type FormInputProps = {
  control: any;
  name: string;
};

type Props = FormInputProps & BaseInputProps;

const FormInput = forwardRef<TamaguiElement, Props>(({ control, name, ...restProps }, ref) => {
  const { field } = useController({
    control,
    defaultValue: '',
    name,
  });

  return <BaseInput ref={ref} value={field.value} onChangeText={field.onChange} {...restProps} />;
});

export default FormInput;
