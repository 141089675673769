import { useCart } from '@/hooks';
import { formatPrice } from '@holo/format';
import type { CartItem as CartItemProps } from '@holo/gql';
import { CounterInput, Image, Input, Text, XStack, YStack } from '@holo/ui';
import { useState } from 'react';

type Props = Omit<CartItemProps, 'createdAt' | 'updatedAt'>;

const CartItem = ({
  id,
  name,
  variantName,
  note: initialNote,
  image,
  quantity,
  unitTotal,
  unit,
  unitQuantity,
}: Props) => {
  const { removeItem, updateItem } = useCart();
  const [isVisible, setIsVisible] = useState(false);
  const [note, setNote] = useState(initialNote || '');

  const toggleNote = () => {
    setIsVisible(!isVisible);
  };

  const handleUpdateNote = (value: string) => {
    setNote(value);
    updateItem({ id, note: value });
    setIsVisible(false);
  };

  const handleUpdateQuantity = (value: number) => {
    if (value) {
      updateItem({ id, quantity: value });
    } else {
      removeItem(id);
    }
  };

  return (
    <YStack mb="$3" pb="$3" borderBottomColor="$border" borderBottomWidth={1}>
      <XStack>
        <Image src={image} width={75} height={75} alt={name || ''} borderRadius={8} />

        <YStack ml="$3" flex={1} marginRight="$3">
          <Text fontWeight="$semi-bold" fontSize="$12" mb="$1">
            {name}
          </Text>

          {!!variantName && (
            <Text fontSize="$12" mb="$1">
              {variantName}
            </Text>
          )}

          <Text fontSize="$12">{`${formatPrice({ amount: unitTotal })} / ${
            unitQuantity && unitQuantity > 1 ? unitQuantity : ''
          } ${unit}`}</Text>

          <YStack mt="$3">
            {initialNote && !isVisible && (
              <Text fontSize="$11" color="$darkGray">
                {initialNote}
              </Text>
            )}

            {isVisible && <Input placeholder="Add notes" value={note} onChangeText={setNote} />}

            <XStack mt="$2">
              {isVisible ? (
                <XStack>
                  {initialNote === note ? (
                    <Text
                      fontSize="$12"
                      textDecorationLine="underline"
                      onPress={() => handleUpdateNote('')}
                      cursor="pointer"
                      mr="$5"
                    >
                      Remove
                    </Text>
                  ) : (
                    <Text
                      fontSize="$12"
                      textDecorationLine="underline"
                      onPress={() => handleUpdateNote(note)}
                      cursor="pointer"
                      mr="$5"
                    >
                      Save
                    </Text>
                  )}

                  <Text fontSize="$12" textDecorationLine="underline" onPress={toggleNote} cursor="pointer" mr="$5">
                    Cancel
                  </Text>
                </XStack>
              ) : (
                <XStack>
                  <Text fontSize="$12" textDecorationLine="underline" onPress={toggleNote} cursor="pointer" mr="$5">
                    {note ? 'Edit note' : 'Add note'}
                  </Text>
                </XStack>
              )}

              {!isVisible && (
                <Text fontSize="$12" textDecorationLine="underline" cursor="pointer" onPress={() => removeItem(id)}>
                  Remove
                </Text>
              )}
            </XStack>
          </YStack>
        </YStack>

        <YStack>
          <CounterInput value={quantity} onChange={handleUpdateQuantity} />
        </YStack>
      </XStack>
    </YStack>
  );
};

export default CartItem;
