import { useFilteredProducts } from '@/hooks/useFilteredProducts';
/* eslint-disable react/jsx-no-constructed-context-values */
import debounce from 'lodash.debounce';
import { createContext, useCallback, useRef, useState } from 'react';

export type ProductCategoriesSpyContextValues = {
  sections: Map<string, HTMLElement>;
  registerSection: (id: string) => (el: HTMLElement) => Map<string, HTMLElement>;
  detectClosest: (options: { offset: number }) => void;
  registerOnPress: (id: string, options: { offset: number; onBeforeScroll?: () => void }) => () => void;
  activeId: string;
};

export const ProductCategoriesSpyContext = createContext<ProductCategoriesSpyContextValues>({
  sections: new Map(),
  registerSection: () => () => new Map(),
  detectClosest: () => {},
  registerOnPress: () => () => {},
  activeId: '',
});

type ProductCategoriesSpyProviderProps = {
  children: React.ReactNode;
};

const ProductCategoriesSpyProvider = ({ children }: ProductCategoriesSpyProviderProps) => {
  const [activeId, setActiveId] = useState('');
  const { clear } = useFilteredProducts();

  const sectionRefs = useRef<Map<string, HTMLElement>>(new Map());
  const registerSection = useCallback((id: string) => (el: HTMLElement) => sectionRefs.current.set(id, el), []);
  const detectClosest = useCallback(
    debounce(({ offset }) => {
      let closestTop = -Infinity;
      let closestId;
      let firstId = '';

      Array.from(sectionRefs.current).forEach(([id, section]) => {
        if (!section) {
          sectionRefs.current.delete(id);
          return;
        }

        const { top: sectionTop } = section.getBoundingClientRect();

        if (sectionTop <= 0 - offset && sectionTop > closestTop) {
          closestTop = sectionTop;
          closestId = id;
        }

        if (!firstId) {
          firstId = id;
        }
      });

      setActiveId(closestId || firstId);
    }, 50),
    [],
  );

  const registerOnPress: ProductCategoriesSpyContextValues['registerOnPress'] = useCallback((id, options) => {
    return () => {
      options.onBeforeScroll?.();
      clear();

      setTimeout(() => {
        const section = sectionRefs.current.get(id);
        if (section) {
          const { top: sectionTop } = section.getBoundingClientRect();
          window.scrollBy({ top: sectionTop + options.offset, behavior: 'smooth' });
        }
      }, 10);
    };
  }, []);

  const values = {
    activeId,
    sections: sectionRefs.current,
    registerSection,
    detectClosest,
    registerOnPress,
  };

  return <ProductCategoriesSpyContext.Provider value={values}>{children}</ProductCategoriesSpyContext.Provider>;
};

export default ProductCategoriesSpyProvider;
