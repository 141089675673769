type FormatPriceArgs = {
  amount: number;
  locale?: string;
  options?: Omit<Intl.NumberFormatOptions, 'style'>;
};

export const SHORT_DATE_FORMAT = 'E, LLL d';
export const SHORT_DATE_FORMAT_WITH_TIME = 'E, LLL d, p';
export const LONG_DATE_FORMAT = 'haaa E, LLL d';
export const MONTH_AND_DAY_DATE_FORMAT = 'LLL d';

export const formatPrice = ({ amount, locale = 'en-US', options = {} }: FormatPriceArgs) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    ...options,
  }).format(amount / 100);
};

export const reversePriceFormat = (price: string) => {
  // @ts-ignore - replaceAll was introduced in node 15
  return parseInt(price.replaceAll('$', '').replaceAll('.', ''), 10);
};

export const formatQuantity = (quantity: number | string) => {
  const numericQuantity = typeof quantity === 'number' ? quantity : parseInt(quantity, 10);
  return numericQuantity > 0 ? numericQuantity / 100 : numericQuantity;
};
